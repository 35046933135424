var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inspection-component page-component" },
    [
      _c("jtl-form", {
        ref: "basicForm",
        on: { formTableAdd: _vm.formTableAdd }
      }),
      _c(
        "a-row",
        { staticStyle: { width: "100%" } },
        [
          _c(
            "a-col",
            { staticClass: "ant-form-item-label", attrs: { span: 4 } },
            [
              _c("div", { staticClass: "ant-form-item-required" }, [
                _vm._v("巡检清单：")
              ])
            ]
          ),
          _c(
            "a-col",
            { attrs: { span: 19 } },
            [
              _c(
                "a-row",
                { staticClass: "inspection-list-table-row" },
                [
                  _c(
                    "a-col",
                    {
                      staticClass: "inspection-check-list-title",
                      attrs: { span: 5 }
                    },
                    [_vm._v("巡检项目")]
                  ),
                  _c(
                    "a-col",
                    {
                      staticClass: "inspection-check-list-title",
                      attrs: { span: 3 }
                    },
                    [_vm._v("检查方法")]
                  ),
                  _c(
                    "a-col",
                    {
                      staticClass: "inspection-check-list-title",
                      attrs: { span: 3 }
                    },
                    [_vm._v("必填")]
                  ),
                  _c(
                    "a-col",
                    {
                      staticClass: "inspection-check-list-title",
                      attrs: { span: 3 }
                    },
                    [_vm._v("结论")]
                  ),
                  _c(
                    "a-col",
                    {
                      staticClass: "inspection-check-list-title",
                      attrs: { span: 3 }
                    },
                    [_vm._v("结果")]
                  ),
                  _c(
                    "a-col",
                    {
                      staticClass: "inspection-check-list-title",
                      attrs: { span: 3 }
                    },
                    [_vm._v("数据类型")]
                  ),
                  _c(
                    "a-col",
                    {
                      staticClass: "inspection-check-list-title",
                      style: {
                        display:
                          _vm.viewType === _vm.ViewModeType.VIEW
                            ? "none"
                            : "block"
                      },
                      attrs: { span: 2 }
                    },
                    [_vm._v("操作")]
                  )
                ],
                1
              ),
              _vm._l(_vm.checkList, function(data) {
                return _c(
                  "a-row",
                  {
                    key: _vm.checkList.indexOf(data),
                    staticClass: "inspection-list-table-row"
                  },
                  [
                    _c("a-col", { attrs: { span: 5 } }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: data.project,
                            expression: "data.project"
                          }
                        ],
                        domProps: { value: data.project },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(data, "project", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _c("a-col", { attrs: { span: 3 } }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: data.method,
                            expression: "data.method"
                          }
                        ],
                        domProps: { value: data.method },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(data, "method", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _c(
                      "a-col",
                      { attrs: { span: 3 } },
                      [
                        _c(
                          "a-checkbox",
                          {
                            model: {
                              value: data.required,
                              callback: function($$v) {
                                _vm.$set(data, "required", $$v)
                              },
                              expression: "data.required"
                            }
                          },
                          [_vm._v(" 是 ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 3 } },
                      [
                        _c(
                          "a-checkbox",
                          {
                            model: {
                              value: data.conclusion,
                              callback: function($$v) {
                                _vm.$set(data, "conclusion", $$v)
                              },
                              expression: "data.conclusion"
                            }
                          },
                          [_vm._v(" 显示 ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 3 } },
                      [
                        _c(
                          "a-checkbox",
                          {
                            model: {
                              value: data.result,
                              callback: function($$v) {
                                _vm.$set(data, "result", $$v)
                              },
                              expression: "data.result"
                            }
                          },
                          [_vm._v(" 显示 ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 3 } },
                      [
                        _c(
                          "a-select",
                          {
                            staticClass: "table-select-inspec",
                            model: {
                              value: data.type,
                              callback: function($$v) {
                                _vm.$set(data, "type", $$v)
                              },
                              expression: "data.type"
                            }
                          },
                          [
                            _c(
                              "a-select-option",
                              { attrs: { value: "TEXT" } },
                              [_vm._v("文本")]
                            ),
                            _c(
                              "a-select-option",
                              { attrs: { value: "NUMBER" } },
                              [_vm._v("数值")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      {
                        style: {
                          display:
                            _vm.viewType === _vm.ViewModeType.VIEW
                              ? "none"
                              : "block"
                        },
                        attrs: { span: 2 }
                      },
                      [
                        _c(
                          "a",
                          {
                            key: data.project + "btn",
                            staticClass: "a-label",
                            on: {
                              click: function($event) {
                                return _vm.deleteCheckItem(data)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              _c(
                "a-row",
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      style: {
                        display:
                          _vm.viewType === _vm.ViewModeType.VIEW
                            ? "none"
                            : "block"
                      },
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.addCheckItem()
                        }
                      }
                    },
                    [_vm._v("+添加")]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c(
            "jtl-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.viewType === _vm.ViewModeType.VIEW
              },
              on: { click: _vm.save }
            },
            [_vm._v("保存")]
          ),
          _c(
            "jtl-button",
            {
              staticStyle: { "margin-left": "20px" },
              on: { click: _vm.cancel }
            },
            [_vm._v("取消")]
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: _vm.title, visible: _vm.visible },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
        },
        [
          _c("jtl-transfer-component", {
            ref: "transfer",
            attrs: { "transfer-data": _vm.transferData, keys: _vm.keys }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }