
import { InspectionEntityModel, InspectionQueryModel } from '@/entity-model/inspection-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del } from './request';

const URL_PATH = `${WORKORDER_BASE_REQUEST_PATH}/inspection`;

class InspectionService implements ICRUDQ<InspectionEntityModel, InspectionQueryModel> {
    async create(model: InspectionEntityModel):Promise<InspectionEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<InspectionEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new InspectionEntityModel().toModel(res);
    }

    async update(model: InspectionEntityModel):Promise<InspectionEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: InspectionEntityModel):Promise<InspectionEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: InspectionQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new InspectionEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new InspectionEntityModel().toModel(item));
    }
}

export default new InspectionService();
