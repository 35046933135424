


























































import { Component, Mixins } from 'vue-property-decorator';
import DrawerComponent from '@/mixins/drawer-component';
import TableComponent from '@/mixins/table-component';
import { InspectionEntityModel, InspectionQueryModel } from '@/entity-model/inspection-entity';
import InspectionService from '@/service/inspection';
import InspectionDetailComponent from '@/pages/dashboard/inspection/inspection-detail.vue';
import { ViewModeType } from '@/model/enum';

@Component({
    components: {
        'inspection-detail-component': InspectionDetailComponent
    }
})
export default class InspectionListComponent extends Mixins(TableComponent, DrawerComponent) {
    InspectionEntityModel = InspectionEntityModel;
    ViewModeType = ViewModeType;
    selectedRecord = '';
    viewType = ViewModeType.VIEW;

    created() {
        this.startFullScreenLoading();
        this.initTable({
            service: InspectionService,
            queryModel: new InspectionQueryModel(),
            tableColumns: InspectionEntityModel.getTableColumns()
        });
        this.getList();
        this.width = '50%';
        this.stopFullScreenLoading();
    }

    editClick(record, viewType) {
        this.selectedRecord = record;
        this.viewType = viewType;
        this.drawerOpen(record);
    }

    showDrawer() {
        this.getList();
        this.drawerVisible = false;
    }
}
